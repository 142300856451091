import React from 'react'
import './Experience.css' 
import back1 from '../../img/back1.png'
import { motion } from 'framer-motion'

const Experience = () => {
   const transition={duration:1.5,type:"spring", bounce:0.2}

  return (
    <div className='experience' id='experience'>
      <div className="back1">

        <img src={back1} alt="" />
      </div>
      
      <div 
         
      className="exp">
        <motion.div 
        whileInView={{left:"0rem"}}
        initial={{left:"-5rem"}}
        transition={transition}
     
     className="txt1">1+
        </motion.div>
        <span>Years</span>
        <span>Experience</span>
      </div>

      <div className="exp">
        <motion.div 
         whileInView={{bottom:"0rem"}}
         initial={{bottom:"-5rem"}}
         transition={transition}
        className="txt1">4+
        </motion.div>

        <span>Completed</span>
        <span>Projects</span>
      </div>

      <motion.div className="exp">
        <motion.div 
         whileInView={{right:"0rem"}}
         initial={{right:"-5rem"}}
         transition={transition}
        className="txt1">3+
        </motion.div>
        <span>Certifications</span>
        <span>Earned</span>
      </motion.div>
    </div>
  )
}

export default Experience
