import React from 'react'
import SliderA from '../SliderA/SliderA'
import back8 from '../../img/back8.png'
import './Works.css'
const Works = () => {
  return (
    <div>
      <section className="w-wrapper" id='portfolio'>
        
        <div className="w-container">
            <div className="s-left">
            <div className="back8">
          <img src={back8} alt="" />
        </div>
                <span>Certifications &</span>
                <span>Milestones</span>
                <span> Having the good knowledge in Html, Css and JS.
Bootstrap for css framework and React for Js framework.
Making daily goals and working on mini projects to develop the tech skills.
                </span>
                <button className='btn-hover color-3 but3'>Learn more</button>
            </div>
        </div>
        
      </section>
      <SliderA/>
    </div>
  )
}

export default Works
