import React from 'react'
import './Services.css'
import Glass from '../../img/glasses.png'
import Heart from '../../img/heartemoji.png'
import Humble from '../../img/humble.png'
import Cards from '../Cards/Cards'
import resume from './Resume_2023.pdf'
import Back13 from '../../img/back13.png'
import { motion } from 'framer-motion'

const Services = () => {
  const transition = {duration:2, type:"spring"}
  return (
    <div className='services' id='service'>
      
      <div className="s-left">
      <div className="back2">
         <img src={Back13} alt="" />
      </div>
        <span>My Awesome</span>
        <span>Skills</span>
        <span>Having a 1 year experience in one of the top MNC company, <br/>effective team player and leadership skills
Ability to manage the clients, <br/> Negotation skills and business managements
        </span>

        <a href={resume}download ><button className="btn-hover color-3 but1">Download CV</button></a>
      </div>
    
    <div className="cards">
      
        <motion.div 
             whileInView={{left:'27.5rem',top:'-2.5rem'}}
             initial={{left:'43rem', top:'-13rem'}}
             transition={transition}
        
        style={{left:'27.5rem',top:'-2.5rem'}}>
        <Cards
        emoji = {Glass}
        heading = {'< Developer />'}
        detail={"Html, Css, Js, React Js"} />
       </motion.div>

       <motion.div 
          whileInView={{left:'59rem', top:'-6rem'}}
          initial={{left:'43rem', top:'-13rem'}}
          transition={transition}
       
       style={{left:'59rem', top:'-6rem'}}>
        <Cards
        emoji = {Heart}
        heading = {'< Design />'}
        detail={"Canva, Swiper,  Vector Images"} />
       </motion.div>

       <div style={{left:'43rem', top:'-13rem'}}>
        <Cards
        emoji = {Humble}
        heading = {'< Adaptive />'}
        detail={"Leadership, Adaptive, Quick Learner"} />
       </div>
    </div>

    </div>
  )
}

export default Services
