import React from 'react'
import './Intro.css'
import Github from '../../img/github.png'
import LinkedIn from '../../img/linkedin.png'
import Instagram from '../../img/instagram.png'
import Floating from '../Floating/Floating'
import GlassEmoji from '../../img/glassesimoji.png'
import Thumbsup from '../../img/thumbup.png'
import crown from  '../../img/crown.png'
import {motion} from 'framer-motion'
import {Link} from  'react-scroll'


import back9 from '../../img/back9.png'
import san from '../../img/san3.png'

const Intro = () => {
const transition ={duration:2, type:"spring", bounce:0.7}

  return (
    <div className="intro">
      <div className="back9">
         <img src={back9} alt="" />
      </div>
       <div className="i-left">
         <div className="i-name">
            <span>
                Hey! I am</span>
                <span className='char'>
                    Sandhya H</span>
                    <span>
                       I am a Front End Web Developer with 1 year of experience in one of the top MNC Organization, with brilliant and innovative thinking ability. 
                    </span>
         </div>
         <Link activeClass='active' to='contact' spy={true} smooth={true}>
         <button className="btn-hover color-3">
            Hire Me
         </button>
         </Link>
         <div className="i-icons">
            <a href="https://github.com/Hsandhya" target='blank'><img src={Github} alt="" /></a>
            <a href="https://www.linkedin.com/in/sandhya-h-7a0917224" target='blank'><img src={LinkedIn} alt="" /></a>
            <a href="https://www.instagram.com/sandhya.__.here/" target='blank'><img src={Instagram} alt="" /></a>
         </div>
       </div>

       <div className="i-right">
        <img src={san} alt="" />
        <img src='' alt="" />
        <img src='' alt="" />
        
      <motion.img
        whileInView={{left:"-29%",top:"-18%"}}
        initial={{left:"-35%",top:"-18%"}}
        transition={transition}
        

        src={GlassEmoji} alt="" />


          <motion.div 
           whileInView={{top:'45%', left:'-25%'}}
           initial={{left:"-1rem"}}
           transition={transition}
          style= {{top:'45%', left:'-25%'}}>
            <Floating 
            
            image={crown} txt1='Web' txt2='Developer'/>       
               </motion.div>

            <motion.div 
             whileInView={{left:'26rem',top:'-1rem'}}
             initial={{left:"20rem"}}
             transition={transition}
            
            style={{left:'26rem',top:'-1rem'}}>
        <Floating 
        
        image={Thumbsup} txt1='Creative' txt2='Hands'/>
       </motion.div>
       </div>
      
    </div>
  )


}



export default Intro
