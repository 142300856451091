import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";


// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination"; 
import './SliderA.css'
import { EffectCoverflow, Pagination } from "swiper";
import swipe1 from '../../img/swipe1.png'
import swipe2 from '../../img/swipe2.png'
import swipe3 from '../../img/swipe3.jpg'
import swipe4 from '../../img/swipe4.jpg'
import swipe5 from '../../img/swipe5.png'
import swipe6 from '../../img/swipe6.png'
import swipe7 from '../../img/swipe7.png'
import swipe8 from '../../img/swipe8.png'
import swipe9 from '../../img/swipe9.png'




const Works = () => {
  return (
    <>
    <Swiper
      effect={"coverflow"}
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={"auto"}
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }}
      pagination={true}
      modules={[EffectCoverflow, Pagination]}
      className="mySwiper"
    >
      <SwiperSlide>
        <img src={swipe4} />
      </SwiperSlide>
      <SwiperSlide>

        <img src={swipe1} />
      </SwiperSlide>
      <SwiperSlide>
        <img src={swipe7} />
      </SwiperSlide>
      <SwiperSlide>
        <img src={swipe2} />
      </SwiperSlide>
      <SwiperSlide>
        <img src={swipe6}/>
      </SwiperSlide>
      <SwiperSlide>
        <img src={swipe8} />
      </SwiperSlide>
      <SwiperSlide>
        <img src={swipe5} />
      </SwiperSlide>
      <SwiperSlide>
        <img src={swipe9}/>
      </SwiperSlide>
      <SwiperSlide>
        <img src={swipe3}/>
      </SwiperSlide>
    </Swiper>
  </>
  )
}

export default Works
