import React, { useState } from 'react'
import './Contact.css'
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import back12 from '../../img/back12.png'


const Contact = () => {

  
    const form = useRef();
    const [done, setDone] = useState(false);
  
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_3d6rfnb', 'template_ojkuezr', form.current, 'UoRI0rA1tpPngVnFe')
        .then((result) => {
            console.log(result.text);
            setDone(true);
        }, (error) => {
            console.log(error.text);
        });
    };
  return (
    <div className='co-wrapper' id='contact'>
     
        <div className="co-left">
        <div className="back12">
      <img src={back12} alt="" />
     </div>
            <span>Get in touch</span>
            <span>Contact-Me</span>
            <span>"To give real service you must add something which cannot be bought <br/>or measured with money,
                 and that is sincerity and integrity."
                   </span>
                   </div>

                   <div className="co-right">
                    <form ref={form} onSubmit={sendEmail}>
                      <div className='box'>
                    <span>Name</span>
                    <input type="text" name='user_name'/>
                  
                    <span>Email</span>
                    <input type="email" name='user_email'/>
                    <span>Phone Number</span>
                    <input type="number" name='number'/>
                    <span>Message</span>
                    
                    <textarea name="message" id="" cols="30" rows="10" ></textarea>   
                   
                    <div>
                    <button className="btn-hover color-3 but2" type='submit'>Submit</button>
                        </div> 

                        <span>
                          {done && "Thanks for Contacting me..!"}
                        </span>
                        </div>
                        </form>         
                   </div>
        
      



    </div>
  )
}

export default Contact
